import { addMonths, parseISO } from "date-fns";
import { LoanEntityDto } from "src/backend";
import { Loan } from "src/types/loan";

import { calculateEntityMla } from "../calculate-entity-mla";
import { calculateEntityTotalCost } from "../calculate-entity-total-cost";
import { getEntityTotalPurchasePrice } from "../get-entity-total-purchase-price";
import { calculateEntityLTC } from "./calculate-entity-ltc";
import { getEntityLowestAmount } from "./get-entity-lowest-amount";

const EstimationsList = [
    {
        name: 'Loan Origination Fees',
        value: 'loanOriginationFees',
        dollarSign: true
    },
    {
        name: 'Est. Rehab/Construction cost (sq/ft)',
        value: 'estRehabConstructionCost',
        dollarSign: true
    },
    {
        name: 'Est Total Fees',
        value: 'estTotalFees',
        dollarSign: true
    },
    {
        name: 'Est Monthly Interest Only Payment',
        value: 'estMonthlyInterestOnlyPayment',
        dollarSign: true
    },
    {
        name: 'Est Monthly Payment',
        value: 'estMonthlyPayment',
        dollarSign: true
    }, {
        name: 'Est Total Interest',
        value: 'estTotalInterest',
        dollarSign: true
    }, {
        name: 'Est Total Cost of Loan',
        value: 'estTotalCostOfLoan',
        dollarSign: true
    }, {
        name: 'Est Number of Payments',
        value: 'numberOfPayments',
        dollarSign: false
    },
    {
        name: 'Est Payoff Date',
        value: 'payoffDate',
        dollarSign: false
    }
]

export class LoanCalculations {

    // private variable loan

    #loan: Partial<Loan>;

    #loanCalcPeriod = 360;

    public idsToExcludeFromMlaCalculation: string[] = [];

    constructor(newLoan: Partial<Loan>) {
        this.#loan = newLoan;
    }

    // public method get loan origination fees

    public getLoanOriginationFees(): number {
        if (!this.#loan?.loanOriginationPoints || !this.#loan?.loanAmount) return 0;

        return (this.#loan.loanOriginationPoints ?? 0) * this.#loan.loanAmount / 100;
    }

    public getEstimateTotalFees = (): number => {
        if (!this.#loan?.loanOtherFees && !this.getLoanOriginationFees()) return 0;

        return this.getLoanOriginationFees() + (this.#loan?.loanOtherFees ?? 0)
    }

    private getInterest = (): number => {
        // if we have index and margin return it
        if (this.#loan?.index && this.#loan?.margin) {
            return (this.#loan.index + this.#loan.margin) / 100;
        }
        // if we don't have an apr return 0
        if (!this.#loan?.apr) return 0;

        // if we don't have index and margin but have apr and loanAmount 
        return this.#loan.apr / 100;
    }

    private getDailyInterest = (): number => {
        if (!this.getInterest() || !this.#loan?.loanAmount) return 0;

        return this.#loan.loanAmount * (this.getInterest() / this.#loanCalcPeriod);
    }

    // get estimated monthly interest only payment

    public getEstimateMonthlyInterestOnlyPayment = (): number => {
        if (!this.getDailyInterest()) return 0;
        return (this.getDailyInterest() * this.#loanCalcPeriod / 12);
    }
    // public method get estimations

    public getMonthlyLoanInterestPayment = (): number => {
        if (!this.getDailyTotalRepayment()) return 0;

        return this.getDailyTotalRepayment() * this.#loanCalcPeriod / 12;

    }

    public getEstimatedTotalInterest = (): number => {
        if (!this.getEstimateMonthlyInterestOnlyPayment() || !this.#loan?.loanTermInMonths) return 0;

        return this.getEstimateMonthlyInterestOnlyPayment() * this.#loan.loanTermInMonths;
    }

    public getEstimatedTotalCostOfLoan = (): number => {
        return this.getEstimatedTotalInterest() + this.getEstimateTotalFees();
    }

    public getEstimatedRehabConstructionCost = (): number => {
        return this.getRehabEntitiesTotalSqFtCost() + this.getConstructionEntitiesTotalSqFtCost();
    }

    public getEstimatedPayOffDate = (): string => {
        if (!this.#loan?.loanTermInMonths || !this.#loan?.closeDate) return '??';
        // calculate based on loan term in months and loan close date
        try {
            const payOffDate = addMonths(parseISO(this.#loan.closeDate), this.#loan.loanTermInMonths);
            return payOffDate.toLocaleDateString();
        } catch (e) {
            return '??';
        }
    }
    public getLoanAssetEntities = (): LoanEntityDto[] => {
        return this.#loan?.loanEntities?.filter(entity => entity.sherpaEntity?.type?.endsWith("ASSET")) ?? [];
    }
    public getLoanCLTV = (): number => {
        if (!this.getLoanEntitiesCMla() || !this.getLoanEntitiesTotalValue()) return 0;
        return this.getLoanEntitiesCMla() / this.getLoanEntitiesTotalValue() * 100;
    }
    public getLoanEntitiesLTC = (): number => {
        return this.getLoanAssetEntities().reduce((acc, entity) => {
            // @ts-ignore
            return acc + calculateEntityLTC({
                ...entity.mostRecentUserInformation,
                ...entity.assetEntityCustomizations
            });
        }, 0);
    }
    public getLoanEntitiesTotalValue = (): number => {
        return this.getLoanAssetEntities().reduce((acc, entity) => {
            // @ts-ignore
            const lowestValue = getEntityLowestAmount({
                type: entity?.sherpaEntity?.type,
                ...entity.mostRecentUserInformation,
                ...entity.assetEntityCustomizations
            });

            return acc + lowestValue;
        }, 0);
    }
    public getLoanEntitiesTotalCost = (): number => {
        return this.getLoanAssetEntities().reduce((acc, entity) => {
            // @ts-ignore
            return acc + calculateEntityTotalCost({
                ...entity.mostRecentUserInformation,
                ...entity.assetEntityCustomizations
            });
        }, 0);
    }
    public getLoanLTC = (): number => {
        if (!this.getLoanEntitiesCMla() || !this.getLoanEntitiesTotalCost()) return 0;

        return ((this.getLoanEntitiesCMla() / this.getLoanEntitiesTotalCost()) * 100);
    }

    public getLoanEntitiesCount = (): number => {
        return this.getLoanAssetEntities().length ?? 0;
    }

    public getEstimations(): any[] {
        return EstimationsList.map(estimation => {
            if (estimation.value === 'loanOriginationFees') {
                return {
                    ...estimation,
                    value: this.formatAsMoney(this.getLoanOriginationFees())
                }
            }
            if (estimation.value === 'estRehabConstructionCost') {
                return {
                    ...estimation,
                    value: this.formatAsMoney(this.getEstimatedRehabConstructionCost())
                }
            }
            if (estimation.value === 'estTotalFees') {
                return {
                    ...estimation,
                    value: this.formatAsMoney(this.getEstimateTotalFees())
                }
            }
            if (estimation.value === 'estMonthlyInterestOnlyPayment') {
                return {
                    ...estimation,
                    value: this.formatAsMoney(this.getEstimateMonthlyInterestOnlyPayment())
                }
            }

            if (estimation.value === 'estMonthlyPayment') {
                return {
                    ...estimation,
                    value: this.formatAsMoney(this.getMonthlyLoanInterestPayment())
                }
            }
            if (estimation.value === 'estTotalInterest') {
                return {
                    ...estimation,
                    value: this.formatAsMoney(this.getEstimatedTotalInterest())
                }
            }
            if (estimation.value === 'estTotalCostOfLoan') {
                return {
                    ...estimation,
                    value: this.formatAsMoney(this.getEstimatedTotalCostOfLoan())
                }
            }

            if (estimation.value === 'numberOfPayments') {
                return {
                    ...estimation,
                    value: this.#loan?.loanTermInMonths
                }
            }

            if (estimation.value === 'payoffDate') {

                return {
                    ...estimation,
                    value: this.getEstimatedPayOffDate()
                }
            }

            return estimation
        })
    }

    public getTotalEntitiesPurchasePrice = (): number => {
        return this.getLoanAssetEntities().reduce((acc, entity) => {
            // @ts-ignore
            return acc + (getEntityTotalPurchasePrice({
                ...entity.mostRecentUserInformation,
                ...entity.assetEntityCustomizations
            }));
        }, 0);
    }

    public formatAsMoney = (value: number): string => {
        // return formatted value as money without dollar sign
        return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
            .replace('$', '');
    }

    public getLoanTermInYears = (): string => {
        if (!this.#loan?.actualLoanAmortization) return "??";

        return (this.#loan.actualLoanAmortization / 12).toFixed(1);
    }

    public getRequestedLoanAmortizationInYears = (): string => {
        if (!this.#loan?.amortizationInMonths) return "??";

        return (this.#loan.amortizationInMonths / 12).toFixed(1);
    }

    private getNumerator = (): number => {
        if (!this.#loan.amortizationInMonths) return 0;
        return (this.getInterest() / this.#loanCalcPeriod) * Math.pow((1 + (this.getInterest() / this.#loanCalcPeriod)), this.#loanCalcPeriod * (this.#loan.amortizationInMonths / 12));
    }

    private getDenominator = (): number => {
        if (!this.#loan.amortizationInMonths) return 0;

        return Math.pow((1 + (this.getInterest() / this.#loanCalcPeriod)), this.#loanCalcPeriod * (this.#loan.amortizationInMonths / 12)) - 1
    }

    private getDailyTotalRepayment = (): number => {
        if (!this.#loan?.loanAmount) return 0;
        return this.#loan.loanAmount * (this.getNumerator() / this.getDenominator());
    }

    private getRehabEntities = (): LoanEntityDto[] => {
        if (!this.getLoanAssetEntities()) return [];

        return this.getLoanAssetEntities().filter(entity => entity.sherpaEntity.type === 'REHAB_COMMERCIAL_RE_ASSET' ||
            entity.sherpaEntity.type === 'REHAB_RESIDENTIAL_RE_ASSET');

    }

    private getConstructionEntities = (): LoanEntityDto[] => {
        if (!this.getLoanAssetEntities()) return [];

        return this.getLoanAssetEntities().filter(entity => entity.sherpaEntity.type === 'CONSTRUCTION_COMMERCIAL_RE_ASSET' ||
            entity.sherpaEntity.type === 'CONSTRUCTION_RESIDENTIAL_RE_ASSET');
    }

    private getRehabEntitiesTotalSqFtCost = (): number => {
        const rehabEntities = this.getRehabEntities();

        if (!rehabEntities.length) return 0;

        return rehabEntities.filter(entity => {
            // @ts-ignore    
            return entity.mostRecentUserInformation.repairCost && entity.mostRecentUserInformation.buildingSquareFootage
        }).reduce((acc, entity) => {
            // @ts-ignore    
            return acc + ((entity.mostRecentUserInformation.repairCost) / entity.mostRecentUserInformation.buildingSquareFootage)
        }, 0);
    }

    private getConstructionEntitiesTotalSqFtCost = (): number => {
        const constructionEntities = this.getConstructionEntities();

        if (!constructionEntities.length) return 0;

        return constructionEntities.
            filter(entity => {
                // @ts-ignore    
                return entity.mostRecentUserInformation.repairCost && entity.mostRecentUserInformation.buildingSquareFootage
            })
            .reduce((acc, entity) => {
                // @ts-ignore    
                return acc + (entity.mostRecentUserInformation.repairCost / entity.mostRecentUserInformation.buildingSquareFootage)
            }, 0);
    }
    // project costs is the sum of all entities repair costs and constructionCost
    public getProjectCosts = (): number => {
        if (!this.getLoanAssetEntities()) return 0;

        return this.getLoanAssetEntities().reduce((acc, entity) => {
            // @ts-ignore    
            return acc + this.getNumericValue(entity.mostRecentUserInformation?.repairCost)
        }, 0);
    }

    private getLoanEntitiesCashDeposit = (): number => {
        if (!this.getLoanAssetEntities()) return 0;

        return this.getLoanAssetEntities().reduce((acc, entity) => {
            // @ts-ignore
            return acc + (this.getNumericValue(entity.mostRecentUserInformation.cashDeposit) + this.getNumericValue(entity.mostRecentUserInformation.earnestMoneyDeposit))
        }, 0);
    }

    private getLoanAdditionalCashNeeded = (): number => {

        return this.getNumericValue(this.#loan.downPayment) - this.getLoanEntitiesCashDeposit();
    }

    public getLoanEntitiesCMla = (): number => {
        if (!this.getLoanAssetEntities()) return 0;

        return this.getLoanAssetEntities()
            .filter(entity => !this.idsToExcludeFromMlaCalculation.includes(entity.sherpaEntity.id))
            .reduce((acc, entity) => {
                // @ts-ignore
                const mla = calculateEntityMla({
                    ...entity.mostRecentUserInformation,
                    ...entity.assetEntityCustomizations,
                    type: entity.sherpaEntity.type
                });
                return acc + mla
            }, 0);
    }

    public setIdsToExcludeFromMlaCalculation = (ids: string[]) => {
        this.idsToExcludeFromMlaCalculation = ids;
    }

    private getNumericValue = (value: string | number): number => {
        // if value is a number, return it
        if (typeof value === 'number') return value;

        if (!value) return 0;
        return parseFloat(value.replace(/,/g, ''));
    }
}